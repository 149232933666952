import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { charityTheme } from 'common/theme/charity';
import { ResetCSS } from 'common/assets/css/style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import Navbar from 'components/NavbarNews';
import DrawerSection from 'components/DrawerSection';
import Footer from 'components/Footer';
import {
  GlobalStyle,
  CharityWrapper,
} from 'containers/Charity/charity.style';
import Seo from 'components/seo';
import {graphql} from "gatsby";
import ComponentRenderer from "../../components/ComponentRenderer";
import CookieNotice from "../../components/CookieNotice";

const Index = ({ data }) => {
  return (
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <Seo title="Home" />
        <ResetCSS />
        <GlobalStyle />
        {/* Start charity wrapper section */}
        <CharityWrapper>
          <Navbar />
          <DrawerProvider>
            <DrawerSection />
          </DrawerProvider>
          <ComponentRenderer data={data.datoCmsPost} />
          <Footer />
          <CookieNotice />
        </CharityWrapper>
        {/* End of charity wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};

export default Index;

export const query = graphql`
query GetPost($slug: String!) {
  datoCmsPost(urlPath: {eq: $slug}) {
    urlPath
    components {
      ... on DatoCmsHeaderBlock {
        id
        model {
          name
        }
        title
        text
        hasButton
        buttonText
        buttonUl
        image {
          url
        }
      }
      ... on DatoCmsBecomeACouncillorForm {
        id
        title
        subtext
        model {
          name
        }
      }
      ... on DatoCmsPictoList {
        id
        model {
          name
        }
        items {
          icon {
            url
          }
          text
          title
        }
        subtitle
        title
        bottomText
      }
      ... on DatoCmsDocumentsBlock {
        id
        title
        subtext
        model {
          name
        }
        items {
          title
          description
          document {
            url
          }
        }
      }
      ... on DatoCmsTextBlock {
        id
        model {
          name
        }
        text
      }
      ... on DatoCmsEsu {
        id
        model {
          name
        }
        title
        description
      }
      ... on DatoCmsBackgroundSection {
        id
        model {
          name
        }
        backgroundImage {
          url
        }
      }
    }
  }
}
`;
