import React, { useState, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Scrollspy from 'react-scrollspy';
import Drawer from 'common/components/Drawer';
import { DrawerContext } from 'common/contexts/DrawerContext';
import InnerWrapper from './drawerSection.style';
import { Link } from 'gatsby';


const DrawerSection = (props) => {
  const [toggleState, setToggleState] = useState(false);
  const { state, dispatch } = useContext(DrawerContext);
  const url = typeof window !== 'undefined' ? window.location.pathname : '';

  const handleActiveClass = () => {
    setToggleState(!toggleState);
  };

  const handleDrawerToggle = () => {
    dispatch({
      type: 'TOGGLE',
    });
    handleActiveClass();
  };

  const data = useStaticQuery(graphql`
query MenuQuery {
  allDatoCmsMenuItem(filter: {published: {eq: true}}, sort: {fields: order}) {
    edges {
      node {
        title
        urlPath
        published
        order
      }
    }
  }
}
  `);

  const scrollItems = [];

  data.allDatoCmsMenuItem.edges.forEach((item) => {
    scrollItems.push(item.node.urlPath.slice(1));
  });

  if (data.allDatoCmsMenuItem.edges.length <= 1) {
    return <React.Fragment />
  }

  return (
    <Drawer
      width="420px"
      placement="right"
      drawerHandler={
        <button
          className={`drawer_btn ${toggleState ? 'active' : ''}`}
          onClick={handleActiveClass}
          aria-label="drawer toggle button"
        >
          <ul className="grid">
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul>
          <i className="flaticon-plus-symbol " />
        </button>
      }
      open={state.isOpen}
      toggleHandler={handleDrawerToggle}
    >
      <InnerWrapper>
        <Scrollspy
          className="scrollspy__menu"
          items={scrollItems}
          offset={-81}
          currentClassName="active"
        >
          {data.allDatoCmsMenuItem.edges.map((menu, index) => (
            <li key={`menu_key${index}`}>
              <Link
                to={menu.node.urlPath}
                className={(url === '/' && menu.node.urlPath === '/') || (menu.node.urlPath !== '/' && url.indexOf(menu.node.urlPath) !== -1) ? 'active' : ''}
                onClick={handleDrawerToggle}
              >
                {menu.node.title}
              </Link>
            </li>
          ))}
        </Scrollspy>
      </InnerWrapper>
    </Drawer>
  );
};

export default DrawerSection;
